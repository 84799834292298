<template>
    <div>
        <v-dialog v-model="bDialogPromotionDetail" persistent @keydown.esc="close" :width="screenWidth + '%'">
            <div class="content-card-dialog" :style="{ 'background-color': `${sColorCode}` }">
                <v-btn class="content-card-button-close-dynamic-color"
                    :style="{ 'border': '2px solid' + sColorTextTypePromotion + '!important'}" icon @click="close">
                    <v-icon :color="sColorTextTypePromotion" size="16px"> mdi-close </v-icon>
                </v-btn>
                <div class="content-permition-detail">
                    <span :style="{ 'color': sColorTextTypePromotion }" class="text-resum-promotion">
                        {{ sTitle }}
                    </span>
                    <br>
                    <img class="img-promotion" :src="sIconURL" alt="">

                    <div class="content-type-promotion">
                        <v-chip class="chip-type-promotion" color="#D3E5FF">{{ sNameType }}</v-chip>
                    </div>
                    <span :style="{ 'color': sColorTextTypePromotion }" class="text-description-promotion">
                        {{ sLongDescription }}
                    </span>
                    <br>
                    <span :style="{ 'color': sColorTextTypePromotion }" class="text-date-promotion">
                        {{ tStatusUpdatedAt }}
                    </span>
                </div>
                <div class="content-btns">
                    <div class="content-btn-back">
                        <v-btn class="button-secondary-dynamic-color"
                            :style="{ 'color': sColorTextTypePromotion + '!important', 'border': '1px solid' + sColorTextTypePromotion + '!important' }"
                            elevation="0" @click="close">
                            Regresar
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        bDialogPromotionDetail: Boolean,
        oItemPromotion: Object,

    },
    data() {
        return {
            screenWidth: 0,
            sColorCode: "#FFFFFF",
            sColorTextTypePromotion: "",
            sTitle: "",
            sIconURL: "",
            sNameType: "",
            sShortDescription: "",
            sLongDescription: "",
            tStatusUpdatedAt: ""
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            // size to range
            if (window.innerWidth < 599) {
                this.screenWidth = 100;
            } else if (window.innerWidth > 600 && window.innerWidth < 959) {
                this.screenWidth = 65;
            } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
                this.screenWidth = 55;
            } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
                this.screenWidth = 40;
            } else if (window.innerWidth > 1904) {
                this.screenWidth = 30;
            }
        },
        close() {
            this.$emit("setDetailPromotion", {})
        },
    },
    watch: {
        bDialogPromotionDetail() {
            if (this.bDialogPromotionDetail) {
                this.sColorCode = this.oItemPromotion.sColorCode;
                this.sTitle = this.oItemPromotion.sTitle;
                this.sIconURL = this.oItemPromotion.sIconURL;
                this.sNameType = this.oItemPromotion.sNameType;
                this.sShortDescription = this.oItemPromotion.sShortDescription;
                this.sLongDescription = this.oItemPromotion.sLongDescription;
                this.tStatusUpdatedAt = this.oItemPromotion.tStatusUpdatedAt;
                this.sColorTextTypePromotion = this.oItemPromotion.sColorTextTypePromotion;
            }
        }
    }
}
</script>

<style scoped>
.content-permition-detail {
    text-align: center !important;
}

.text-resum-promotion {
    font-size: 28px;
    font-weight: bold;
    overflow-wrap: break-word;

}

.img-promotion {
    margin-top: 15px;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
}


.chip-type-promotion {
    font-size: 10px !important;
    font-weight: 500;
    color: #2759A2 !important;
    height: 20px;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.text-description-promotion {
    font-weight: 400;
    overflow-wrap: break-word;
    font-size: 14px;
}

.text-date-promotion {
    font-weight: 300;
    font-size: 12px;
}

.content-btns {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
}

.content-card-button-close-dynamic-color {
    /* border: 2px solid white !important; */
    border-radius: 5px !important;
    width: 20px !important;
    height: 20px !important;
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.button-secondary-dynamic-color {
    /* box-shadow: 0px 3px 6px white !important; */
    border-radius: 21px !important;
    opacity: 1;
    text-align: center;
    font-size: 14px !important;
    letter-spacing: 0px !important;
    background-color: transparent !important;
    /* color: white !important; */
    text-transform: initial !important;
    border: 1px solid white;
    font-weight: 400 !important;
}
</style>