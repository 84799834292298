<template>
    <div v-if="aPromotions.length >0" class="content-promotions" ref="ContentDashboardPromotions">
        <div class="content-title title-section-dashboard">
            <v-chip class="chip-title" color="#D3E5FF">Promociones</v-chip>
        </div>
        <div class="content-carousel-promotions-global">
            <div class="content-prev-carousel">
                <span @click="setPrevCarousel" class="material-icons icons-carousel-promotion">
                    arrow_back_ios
                </span>
            </div>

            <v-carousel v-model="iCarousel" hide-delimiter-background :hide-delimiters="true"
                :height="bMobile ? '200' : '85'">
                <template v-slot:prev="{ on, attrs }">
                    <span id="prev-carousel" v-show="false" v-bind="attrs" v-on="on"
                        class="material-icons icons-carousel-promotion">
                        arrow_back_ios
                    </span>
                </template>
                <template v-slot:next="{ on, attrs }">
                    <span id="next-carousel" v-show="false" v-bind="attrs" v-on="on"
                        class="material-icons icons-carousel-promotion">
                        arrow_forward_ios
                    </span>
                </template>
                <v-carousel-item v-for="(item, i) in aPromotions" :key="i">
                    <v-sheet class="content-carousel-promotions" :color="item.sColorCode" height="100%" tile>
                        <div :style="{ 'background-color': item.sColorCodeTypePromotion }"
                            class="content-type-promotion">
                            <span class="text-type-promotion poppins">
                                {{ item.sNameType }}
                            </span>
                        </div>

                        <div class="content-message-promotion ">
                            <img class="img-promotion" :src="item.sIconURL" alt="">

                            <span :style="{ 'color': item.sColorTextTypePromotion }"
                                class="text-message-promotion poppins"
                                :class="bMobile ? 'd-inline-block text-truncate' : ''">
                                {{ item.sTitle }}
                            </span>
                        </div>
                        <div class="content-divider">
                            <v-divider :vertical="bMobile ? false : true" class="divider-vertical"></v-divider>
                        </div>

                        <div class="content-description-promotion">
                            <span :style="{ 'color': item.sColorTextTypePromotion }" class="text-description-promotion">
                                {{ item.sShortDescription }}
                            </span>
                        </div>

                        <div class="content-btn">
                            <v-btn class="button-primary btn-search poppins" elevation="0"
                                @click="setDetailPromotion(item)">
                                Ver promoción
                            </v-btn>
                        </div>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>

            <div class="content-next-carousel">
                <span @click="setNextCarousel" class="material-icons icons-carousel-promotion">
                    arrow_forward_ios
                </span>
            </div>
        </div>
        <v-container class="max-width">
            <v-pagination v-model="iPagination" circle class="my-0 pagination-poromotion" :length="aPromotions.length">
            </v-pagination>
        </v-container>
        <dialog-promotion-detail @setDetailPromotion="setDetailPromotion()"
            :bDialogPromotionDetail="bDialogPromotionDetail" :oItemPromotion="oItemPromotion" />
    </div>

    <div v-else lass="content-promotions" ref="ContentDashboardPromotions">
        <div class="content-empty-promotions">
            Sin promociones
        </div>
    </div>
</template>

<script>
import DialogPromotionDetail from './DialogPromotionDetail.vue';
export default {
    data() {
        return {
            iCarousel: 0,
            iPagination: 1,
            aPromotions: [],
            bMobile: false,
            bDialogPromotionDetail: false,
            oItemPromotion: {},
        };
    },
    beforeMount() {
        this.getPromotions();
    },
    updated() {
        this.onResize()
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        onResize() {
            if (this.$refs.ContentDashboardPromotions.clientHeight > 0) {
                this.$store.commit("setContentPromotionsDashboard", this.$refs.ContentDashboardPromotions.clientHeight)
            }


            if (window.innerWidth > 960) {
                this.bMobile = false;
            }
            else {
                this.bMobile = true;
            }
        },
        setPrevCarousel() {
            document.getElementById("prev-carousel").click();
        },
        setNextCarousel() {
            document.getElementById("next-carousel").click();
        },
        setDetailPromotion(item) {
            this.bDialogPromotionDetail = !this.bDialogPromotionDetail;
            this.oItemPromotion = item;

        },
        getPromotions() {
            this.$store
                .dispatch("getPermissionsByUserGlobal")
                .then((resp) => {

                    if (this.getPermissionsActionsGlobal()) {
                        DB.get(
                            `${URI}/promotions/active`,
                            {
                                headers: {
                                    Authorization: `Bearer ${this.$store.state.sToken}`,
                                },
                                params: {

                                },
                            }
                        )
                            .then((response) => {
                                this.aPromotions = response.data.results.results.map(e => {
                                    return {
                                        ...e,
                                        sColorCode: e.oPromotionType.sColorCode,
                                        sColorCodeTypePromotion: "#ffffffcc",
                                        sColorTextTypePromotion: this.setColorTextTypePromotion(e.oPromotionType.sPromotionTypeId),
                                        sIconURL: e.oPromotionType.sIconURL,
                                        sNameType: e.oPromotionType.sName
                                    }
                                });
                                this.$store.commit("refresher", false);

                            })
                            .catch((error) => {
                                this.mixError(error.response.data.message, error.response.status);
                            });
                    }
                    this.$emit("setExistPromotion", (this.aPromotions.length > 0));
                })
                .catch((error) => {
                    this.mixError(error.message, error.code);
                });


        },
        setColorTextTypePromotion(sPromotionType) {
            if (sPromotionType === "c8b3e1d8-2346-4608-87bc-9ef817afcbfd" || sPromotionType === "f43ea60e-8e1d-469c-82b4-fa84650a2401") {
                return "#2759A2"
            } else {
                return "#FFFF"
            }
        }
    },
    computed: {
        refresh: function () {
            return this.$store.state.refresh;
        },
        bAdminPromotionsClient() {
            return this.$store.state.bAdminPromotionsClient;
        },
        bShowPromotionsClient() {
            return this.$store.state.bShowPromotionsClient;
        }
    },
    watch: {

        aPromotions() {
            this.$emit("setExistPromotion", (this.aPromotions.length > 0));
        },
        refresh: function () {
            this.getPromotions();
        },
        iCarousel() {
            this.iPagination = this.iCarousel + 1
        },
        iPagination() {
            this.iCarousel = this.iPagination - 1
        }
    },
    components: { DialogPromotionDetail }
}
</script>

<style scoped>
.content-empty-promotions {
    height: 175px;
    border: #2759A2 dashed 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 12px;
    /* background-color: #2759A2; */
}

.content-title {
    /* margin-left: 22px; */
    margin-bottom: 5px;
    margin-top: 5px;
}

.chip-title {
    font-size: 13px !important;
    color: #2759A2;
    height: 20px;

}

.content-carousel-promotions-global {
    display: flex;
    align-items: center;
}

.content-carousel-promotions {
    border-radius: 10px !important;
    border: #2759A2 solid 1px !important;
    display: flex;
}

.content-prev-carousel {
    display: flex;
    align-content: center;
    width: 15px;
}

.content-next-carousel {
    display: flex;
    align-content: center;
    width: 15px;
}

.content-pointers-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.icons-carousel-promotion {
    color: #2759A2;
    font-weight: 900 !important;
}

.icons-carousel-promotion:hover {
    color: #2759A2;
    font-weight: 900 !important;
    transform: scale(1.10);
    cursor: pointer;

}

.item-carousel-selected {
    border: solid 1px #2759A2;

    cursor: pointer;
    height: 10px;
    max-height: 10px;
    min-height: 10px;
    width: 10px;
    max-width: 10px;
    min-width: 10px;
    border-radius: 100px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.item-carousel {
    background-color: #2759A2;
    cursor: pointer;
    height: 6px;
    max-height: 6px;
    min-height: 6px;
    width: 6px;
    max-width: 6px;
    min-width: 6px;
    border-radius: 100px;
    margin-left: 5px;
    margin-right: 5px;


}

.content-type-promotion {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    display: flex;
    align-self: center;
    justify-content: center;
    background-color: #C9DCF7;
    height: 83px;
    width: 25px;
    border-radius: 0px 8px 8px 0px;
}

.text-type-promotion {
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #2759A2;
    opacity: 1;
    display: flex;
    align-self: center;
}

.content-message-promotion {
    display: flex;
    width: 30%;
    min-width: 30%;
    max-width: 30%;
    align-items: center;
    line-height: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon-message-promotion {
    font-size: 60px;
    color: white;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.text-message-promotion {
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 600;
    font-family: "Poppins", sans-serif;



}

.content-divider {
    margin-top: 10px;
    margin-bottom: 10px;
}

.divider-vertical {
    margin-left: 15px;
    margin-right: 15px;
    border-color: #ffffff33 !important;

}

.content-description-promotion {
    display: flex;
    width: 60%;
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-description-promotion {
    font-size: 12px;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    color: white;
    display: flex;
    align-items: center;
    text-align: left;
}

.content-btn {
    display: flex;
    align-items: center;
    width: 150px;
    margin-left: 10px;
    margin-right: 15px;
}

.img-promotion {
    margin-left: 15px;
    margin-right: 15px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
}



/*#region modo-responsivo */
/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }



    .content-carousel-promotions-global {
        display: flex;
        align-items: center;
    }

    .content-carousel-promotions {
        border-radius: 10px !important;
        display: block;
    }

    .content-type-promotion {
        writing-mode: inherit;
        transform: rotate(0deg);
        display: flex;
        align-self: center;
        justify-content: center;
        background-color: #C9DCF7;
        height: 15px;
        width: auto;
        border-radius: 5px 5px 0px 0px;
    }

    .text-type-promotion {
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #2759A2;
        opacity: 1;
        display: flex;
        align-self: center;
    }

    .content-message-promotion {
        display: flex;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        justify-content: center;
        align-items: center;
        line-height: 1;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon-message-promotion {
        font-size: 60px;
        color: white;
        display: flex;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
    }

    .img-promotion {
        margin-left: 0px;
        margin-right: 15px;
        height: 40px;
        max-height: 40px;
        min-height: 40px;
    }

    .text-message-promotion {
        font-size: 16px;
        color: white;
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 600;
        font-family: "Poppins", sans-serif;

    }

    .content-divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .divider-vertical {
        margin-left: 15px;
        margin-right: 15px;
        border-color: #ffffff33 !important;

    }

    .content-description-promotion {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0px;
        margin-right: 0px;
        min-height: 20%;
    }

    .text-description-promotion {
        font-size: 12px;
        font-family: "Poppins", sans-serif;
        color: white;
        display: flex;
        align-items: center;
        text-align: center;
        overflow-wrap: break-word;

    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 15px;
    }
}

/*#endregion XS*/
/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }


    .content-carousel-promotions-global {
        display: flex;
        align-items: center;
    }

    .content-carousel-promotions {
        border-radius: 10px !important;
        display: block;
    }

    .content-type-promotion {
        writing-mode: inherit;
        transform: rotate(0deg);
        display: flex;
        align-self: center;
        justify-content: center;
        background-color: #C9DCF7;
        height: 15px;
        width: auto;
        border-radius: 5px 5px 0px 0px;
    }

    .text-type-promotion {
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #2759A2;
        opacity: 1;
        display: flex;
        align-self: center;
    }

    .content-message-promotion {
        display: flex;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        justify-content: center;
        align-items: center;
        line-height: 1;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon-message-promotion {
        font-size: 60px;
        color: white;
        display: flex;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
    }

    .text-message-promotion {
        font-size: 16px;
        color: white;
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }

    .content-divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .divider-vertical {
        margin-left: 15px;
        margin-right: 15px;
        border-color: #ffffff33 !important;

    }

    .content-description-promotion {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0px;
        margin-right: 0px;
        min-height: 20%;
    }

    .text-description-promotion {
        font-size: 12px;
        font-family: "Poppins", sans-serif;
        color: white;
        display: flex;
        align-items: center;
        text-align: center;
        overflow-wrap: break-word;

    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 15px;
    }

}

/*#endregion SM*/
/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD*/
/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG*/
/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL*/
/*#endregion modo-responsivo */
</style>